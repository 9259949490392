<template>
  <div class="industry" v-loading="loading">
    <!-- <h2 class="industry-title">
       行业列表
     </h2>-->
    <div class="industry-content app-container">
      <ul class="industry-main">
        <!--<li class="industry-list" v-for="(item, index) in industryList" :key="index"
            @click="toIndustryDetails(item.ID)">
          <div class="list-title">
            {{item.Name}}
          </div>
          <div class="list-arrow">
            <span>行业速看</span>
            <img src="@/assets/career/industry/right-arrow.png"/>
          </div>
        </li>-->
        <li class="industry-list" v-for="(item, index) in industryList" :key="index">
          <div class="list-item" @click="toIndustryDetails(item.ID)">
            <div class="list-title" :title="item.Name">
              {{item.Name}}
            </div>
            <img :src="item.CoverImage?item.CoverImage:defaultImgCover" alt="">
          </div>
        </li>
        <li class="pagination">
          <el-pagination
            v-show="industryMainData.Total > 0"
            background
            :page-size="9"
            layout="prev, pager, next"
            :total="industryMainData.Total"
            :current-page.sync="currentPage"
            @current-change="handlePagePosition"
          >
          </el-pagination>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import API from '@/api/config'
  import {scrollTo} from '@/utils/scroll-to'
  // import {IndustryList} from '@/api/career/industry'

  export default {
    name: "industry-index",
    data() {
      return {
        loading: false,
        industryMainData: '',
        industryList: '',
        currentPage: 1,

        industry: {
          PageIndex: 1,
          PageSize: 9
        },
        defaultImgCover: require('@/assets/tool/industry/home-news-top.png')
      }
    },

    created() {

      this.getIndustryList(this.industry)
    },

    watch: {
      currentPage: function () {
        this.industry.PageIndex = this.currentPage
        this.getIndustryList(this.industry)
      }
    },

    methods: {
      toIndustryDetails(id) {
         this.$router.push({
        path: "/industry-detail",
        query: { id: id }
      });
      },

      handlePagePosition() {
        scrollTo(0, 300)

        // if(this.currentPage === this.industryMainData.TotalPage){
        //   document.documentElement.scrollTop = 20
        // }
      },

      getIndustryList(param) {
        
        this.loading = true

        API.Career.IndustryList(param).then(res => {
          this.industryMainData = res.data

          this.industryList = res.data.Data

          this.loading = false
        }).catch(err => {
          // this.$message.error(err.message)
          this.loading = false
        })
      }
    }
  }
</script>

<style scoped lang="less">
  // @import "~@/styles/base.scss";

  .industry {
    width: 100%;
    background-color: var(--color-grey);
    padding: .5rem 0;

    .industry-content {
      min-height: 80vh;
      margin: 0 auto;
      background-color: #f9f9f9;
      box-shadow: 0 2px 6px rgba(87, 113, 173, 0.2);
      .industry-title {
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        color: var(--text-font-color);
        margin-top: 0;
      }

      .industry-main {
        min-height: 80vh;
        padding: 2rem 1rem 6rem;
        margin: 0 auto;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        .industry-list {
          box-sizing: border-box;
          width: 33.333%;
          padding: 1rem 3rem;
          position: relative;

          .list-item {
            /*width: 20rem;*/
            padding: 1rem;
            border: 1px solid var(--color-grey);
            border-radius: .3rem;
            box-shadow: 0 2px 4px 0 rgba(87, 113, 173, 0.1);
            transition: box-shadow .2s linear;

            .list-title {
              margin-bottom: 1rem;
              font-size: 18px;
              font-weight: 600;
              color: #555;
              text-align: center;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            img {
              width: 100%;
              height: 15rem;
            }

            &:hover {
              cursor: pointer;
              box-shadow: 0 4px 8px 0 rgba(87, 113, 173, 0.4);
            }

          }

          /*    &:nth-child(even) {
                background-color: #ffff;
              }
              &:nth-child(odd) {
                background-color: #f9f9f9;
              }
    */
          /*&:hover {*/
          /*border: 1px solid red;*/
          /*cursor: pointer;*/
          /*!*font-style: italic;*!*/

          /*.list-arrow {*/
          /*right: 20%;*/
          /*visibility: visible;*/
          /*opacity: 1;*/
          /*}*/
          /*}*/

          .list-arrow {
            width: 8rem;
            position: absolute;
            right: 30%;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            justify-content: space-between;
            visibility: hidden;
            opacity: 0;

            transition: all .2s ease-in-out;

            span {
              line-height: 1.6;
              color: var(--color-red);
              font-size: 20px;
            }

            img {
              vertical-align: top;
              width: auto;
              height: 2rem;

            }
          }
          /* .list-arrow1{
             right: 20%;
           }
           .list-arrow1{
             right: 40%;
           }*/

        }

        .pagination {
          position: absolute;
          bottom: 2rem;
          left: 50%;
          transform: translateX(-50%);
        }
      }

    }
  }
</style>
<style lang="less">
.industry .el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: rgba(var(--themecolor),0.75);
}
.industry .el-pagination.is-background .el-pager li:not(.disabled):hover{
  color: rgba(var(--themecolor),0.75);
  background-color: rgba(var(--themecolor),0.2);
}
.industry .el-pagination.is-background .el-pager li:not(.disabled).active:hover{
  color: #FFF;
  background-color: rgba(var(--themecolor),0.75);
}
.industry .el-pagination.is-background .btn-next:hover, .industry .el-pagination.is-background .btn-prev:hover, .industry .el-pagination.is-background .el-pager li:hover{
  background-color: rgba(var(--themecolor),0.2);
}
</style>